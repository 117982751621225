.ag-cell-button {
	background-color: #868e96;
	color: #fff;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	font-weight: bold;
	cursor: pointer;
	i {
		margin-right: 5px;
	}

	&:hover {
		background-color: #949ba3;
	}
}
