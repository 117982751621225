.manager-error {
  position: fixed;

  div.alert {
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.35);

    div.alert-heading {
      margin: 0;
    }

    p {
      white-space: nowrap;
    }
  }

  bottom: 50px;
  right: 50px;

  z-index: 9000;
}


// Override padding on checkboxes in ag-grid
div.ag-row > div > span > div.checkbox {
  padding: 0;
}
