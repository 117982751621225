.import-drop-area {
    cursor: pointer;
    padding: 5px;
    color: #74708d;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid hsl(0,0%,80%);
    border-radius: 4px;
    min-height: 38px;
}

.span-link {
    cursor: pointer;
    color: #E53057;
}
