.form-group .react-datepicker-wrapper {
	width: 100%;
}

.leaflet-container {
  background-color: #EAEAEA;
  width: 100%;
  height: 100%;
  border: 0;
  z-index: 0;
}

.sr-only {
  display: none;
}

#highlightAll {
  margin-top: 5px;
  margin-right: 10px;
}
