.directory-component {
    .modal-footer {
        margin: -1px calc(-25px - 1px) 0;
        padding: 20px;
    }

    .modal-filter-preview-hidden {
        opacity: 0;
    }

    .modal-filter-preview-show {
        animation: fadein 2s;
    }

    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
}

.directory-details {
    margin-top: 35px;
    background-color: #fff;
    min-height: calc(100vh - 279px);

    .toolbar {
        text-align: right;
    }

    .p-0 {
        padding-left: 0px;
        padding-right: 0px;
    }
    .directory-info {
        padding: 10px;
        background-color: #eee;
    }

    .directory-actions {
        padding: 20px;
        text-align: center;

        .col-md-12 {
            display: flex;
            flex-direction: column;

            // Center import contacts button
            button {
                flex-direction: column;
            }
        }
    }

    .directory-history {
        padding: 20px;
        background-color: #eee;
    }

    .directory-flex {
        display: flex;
        flex-direction: column;
    }

    .directory-filters {
        padding: 20px;
        flex: 1;

        .columns-4 {
            column-count: 4;
        }

        .campaign-segment {
            font-size: 8pt;

            div.icon {
                display: flex;
                align-items: center;

                img {
                    height: 18px;
                    margin-right: 8px;
                }
            }

            b {
                margin-right: 5px;
                margin-left: 5px;
            }
        }

        .filter-times {
            i.feather {
                margin-right: 5px;
            }

            font-size: 10pt;
            font-weight: 600;
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 5px;
        }

        .filter-cards {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .f-card {
                display: flex;
                flex-direction: column;
                flex: 0 1 calc(25% - 1em);
                height: 290px;

                border: 1px solid #eee;
                border-radius: 6px;
                padding: 10px;
                margin-bottom: 22px;

                .flex-spacer {
                    flex: 1;
                }

                .icon {
                    display: inline-block;
                    img {
                        height: 24px;
                        width: 24px;
                    }
                }

                .card-title .icon {
                    margin-right: 5px;
                }

                .f-automatic {
                    text-align: right;
                    color: #eee;
                }

                .card-campseg {
                    background-color: #eee;
                    margin-left: -10px;
                    margin-right: -10px;
                    margin-bottom: 5px;
                    height: 60px;

                    .row {
                        padding-top: 5px;
                    }

                    .align-self-center {
                        text-align: center;
                    }

                    .t-segment {
                        small {
                            padding-left: 5px;
                        }
                    }
                }

                .f-spark {
                    height: 75px;
                    padding-top: 12px;
                    svg {
                        *margin-top: 10px;
                    }
                }

                @media (min-width: 1300px) {
                    .f-spark {
                        *height: 45px;
                    }
                }


                .btn-group {
                    display: flex;
                    flex: 1;
                    height: 30px;
                    button {
                        flex: 1;
                        padding-top: 0px;
                        padding-bottom: 0px;

                        i {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        .filter-row {
            border: 1px solid #eee;
            border-radius: 6px;
            padding: 10px;
            margin-bottom: 5px;

            .filter-name {
                font-size: 1.1em;
                font-weight: bold;
            }

            .filter-segment {
                .segment-name {
                    font-size: 1.1em;
                }

                .segment-generator {

                }
            }

            div .icon {
                display: inline-block;
                img {
                    height: 18px;
                    margin-right: 10px;
                }
            }

            .sparkline-chart {
                cursor: pointer;
            }
        }

        .filter-row:hover {

        }

        .filter-active {
            background-color: #e5faee;
        }
    }

    .directory-workers {
        padding: 20px;
        background-color: #eee;

        .list-group-horizontal {
            flex-direction: row;
            .list-group-item {
                flex: 1 1 auto !important;
                img {
                    height: 22px;
                    margin-right: 10px;
                }

                .worker-status {
                    float: right;
                    font-size: 10pt;
                    cursor: pointer;
                }

                .worker-status:hover {
                    text-decoration: underline;
                }

                .worker-edit {
                    float: right;
                    margin-left: 10px;
                    cursor: pointer;
                }

                .worker-remove {
                    float: right;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }

            .worker-offline {
                opacity: 0.6;
            }
        }


    }
}

.tabs-page {
    //border-bottom: 0;
}

.tabs-page a {
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.1);
    border-radius: 0;
    font-family: "Montserrat", sans-serif;
    color: #495057;
    font-weight: 600;
    font-size: 1em;
    padding: 10px;
}

.tabs-page.nav-tabs .nav-link {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.tabs-page a.active {
    box-shadow: 0px -4px 0 0 #E53057;
    background: #f5f7fa;
}

input.form-control {
    line-height: 1.271;
}

td.order-action {
    width: 48px;
}

h3.popover-header {
    margin: 0;
}


.spacer {
    flex: 1;
}

.xxl-only {
    display: none;
}

@media (min-width: 1940px) {
    .xxl-only {
        display: initial;
    }
    .xxl-hidden {
        display: none;
    }
}
