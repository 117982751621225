.cbFormCont {
    display: flex;
    flex-direction: column;
    max-width: 270px;
}
.cbForm {
    flex: 1;
}
.cbFormButton {
    padding-top: 20px;
    text-align: right;
}
.cbDate {
    width: 100%;
}