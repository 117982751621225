.wide-dialog {
	width: 90%;
	max-width: 90%;
	color: #444;

	.badge {
		color: #fff;
		margin-left: 5px;
	}

	.modal-content {
		min-height: 70vh;
	}


	.show-empty-check {
		input[type=checkbox] {
			margin-top: 5px;
			margin-left: 4px;
		}
		label {
			color: #666;
			text-transform: none;
			font-weight: 100;
		}
	}

	.order-confirmation {
		background-color: #fcfcfc;
		border-radius: 2px;
		padding: 5px;

		.col-split {
			width: 1px;
			background-color: #eee;
		}

		.sms-messages {
			margin-right: 5px;
		}
		.snd-messages {
			margin-left: 5px;
			audio {
				width: 100%;
			}
		}
	}

	.message {
		padding-bottom: 10px;
	}

	.expandable-text {
		cursor: pointer;
	}
}

div.additional-info {
	max-height: 200px;
	overflow-y: auto;
}

.col-actions {
	text-align: right;
	ul li {
		list-style: none;
	}

}

button.order-action {
	margin: 5px;
}

.activity-debug {
	padding: 20px;
}
