.available-methods {
	border-right: 1px solid #eee;
	padding-right: 5px;
}

.used-methods {
	padding-left: 5px;

	div.method-item {
		padding: 10px;
		border-radius: 2px;
		border-bottom: 1px solid #eee;

		span.custom-accordion-toggle {
			cursor: pointer;
		}

		span.title {
			font-size: 1.5em;
			margin-left: 10px;
		}

		.button-toolbar {
			text-align: right;
			margin-top: 20px;

			button {
				margin-left: 5px;
			}
		}

		&:last-child {
			border-bottom: 1px solid transparent;
		}

		ol li div:first-child {
			font-weight: bold;
			padding: 5px;
			background-color: #eee;
		}

		ol li div button {
			margin-left: 2px;
			margin-right: 2px;
		}

		.editor-btns {
			text-align: right;
			button {
				margin-left: 5px;
			}
		}

		.merge-field {
			text-align: right;
		}
		.merge-field span {
			cursor: pointer;
		}
		.merge-field span:hover {
			text-decoration: underline;
		}
	}

}

tbody.merge-fields tr td {
	cursor: pointer;
}
