.directory-country {
    img {
        margin-right: 5px;
    }
    text-align: right;
}

.no-link {
    color: #E53057;
    cursor: pointer;
}

.no-link:hover {
    color: #6B30A1;
}
