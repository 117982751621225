.smsCard {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    min-height: 700px;
    padding-top: 20px;
}
.smsContainer {
    display: flex;
    flex-direction: row;
}
.smsMandatory {
    flex-direction: column;
    display: flex;
    min-width: 500px;
    padding-bottom: 10px;
}
.smsOptional {
    min-width: 500px;
    display: flex;
    flex-direction: column;
}
.smsActions {
    padding-top: 40px;
}
.smsActionItem {
    display: flex;
}
.smsItem {
    flex: 1;
    width: 300px;
    margin-bottom: 20px;
}
.smsError {
    color: rgb(246, 102, 102);
}
.smsMessage {
    width: 470px;
}
